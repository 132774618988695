$rte-editor-min-height: 7rem;

quill-editor {
  border: none;
  display: block !important;
  min-height: $rte-editor-min-height;
}

.ql-editor {
  min-height: $rte-editor-min-height;
}
