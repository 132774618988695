.btn-link, .btn-link:visited, .btn-link:focus {
  &:hover {
    border: 1px solid rgba($body-color, 0.5);
  }
}

.pseudo-link {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  i.fal {
    min-width: 20px;
  }
}
