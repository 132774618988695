/*!
 * Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 * Copyright 2023 Fonticons, Inc.
 */
@import '../variables';

$fa-custom-icons: (
  android-more-vertical: "\e02b",
  ios-external-options: "\e02c",
  ksc-addresses: "\e00e",
  ksc-advertising: "\e00f",
  ksc-app-qr: "\e010",
  ksc-appointments: "\e012",
  ksc-arrow-left: "\e02a",
  ksc-articles: "\e011",
  ksc-cancellations: "\e013",
  ksc-candles: "\e014",
  ksc-contact: "\e015",
  ksc-dashboard: "\e016",
  ksc-donations: "\e028",
  ksc-flower: "\e017",
  ksc-funeral-prints: "\e018",
  ksc-home: "\e026",
  ksc-images: "\e019",
  ksc-imprint: "\e01a",
  ksc-invitations: "\e01b",
  ksc-memorybook: "\e01c",
  ksc-music: "\e01d",
  ksc-orders: "\e01e",
  ksc-person: "\e01f",
  ksc-postal-pension-advance: "\e02d",
  ksc-precaution: "\e020",
  ksc-privacy: "\e021",
  ksc-quotes: "\e027",
  ksc-settings: "\e022",
  ksc-statistics: "\e024",
  ksc-videos: "\e025",
);

@font-face {
  font-family: 'Font Awesome Kit';
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url('#{$fa-custom-font-path}/custom-icons.woff2') format('woff2'),
    url('#{$fa-custom-font-path}/custom-icons.ttf') format('truetype');
}

.fak, .#{$fa-css-prefix}-kit {
  font-family: "Font Awesome Kit";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--#{$fa-css-prefix}-display, #{$fa-display});
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
}

@each $name, $icon in $fa-custom-icons {
  .fak.#{$fa-css-prefix}-#{$name}:before, .fa-kit.#{$fa-css-prefix}-#{$name}:before { content: unquote("\"#{ $icon }\""); }
}
