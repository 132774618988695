.candle-color-blue {
  background-color: #51658e;
}

.candle-color-yellow {
  background-color: #ecc069;
}

.candle-color-green {
  background-color: #64a96c;
}

.candle-color-lightblue {
  background-color: #7ab2c7;
}

.candle-color-purple {
  background-color: #8f77a2;
}

.candle-color-red {
  background-color: #a76262;
}
