@use 'sass:color';

@import 'src/custom-scss/variables';
@import 'src/custom-scss/mixins';
@import 'bootstrap/scss/mixins';

$nslocal: 'ngx-gdpr-cookie-info';

//----------------------
// Cookie dialog info table display variables
//----------------------
$mpac-cookie-info__hdr-c: $body-color !default;
$mpac-cookie-info__hdr-cnt-ovf: visible !default;

$mpac-cookie-info__hdr-cnt-chk-cookie-m: (
  lr: 0,
  tb: rem(5),
) !default;
$mpac-cookie-info__hdr-cnt-chk-cookie-mat-cbx-inner-mr: rem(15) !default;
$mpac-cookie-info__hdr-cnt-chk-cookie-mat-cbx-lbl-fw: bold !default;
$mpac-cookie-info__hdr-cnt-chk-cookie-mat-cbx--disabled-lbl-fw: $color-silver-chalice !default;
$mpac-cookie-info__hdr-cnt-chk-cookie-mat-cbx-lbl-fs: rem($font-size--default) !default;

//----------------------
// Cookie dialog info table styles
//----------------------

.#{$nslocal} {
  &__hdr {
    color: $mpac-cookie-info__hdr-c;
    
    .mat-content {
      overflow: $mpac-cookie-info__hdr-cnt-ovf;
    }

    //----------------------
    // Checkbox
    //----------------------

    lib-gdpr-checkbox-control {
      margin: map-get($mpac-cookie-info__hdr-cnt-chk-cookie-m, tb)
        map-get($mpac-cookie-info__hdr-cnt-chk-cookie-m, lr);
    }

    .mat-checkbox-inner-container {
      margin-right: $mpac-cookie-info__hdr-cnt-chk-cookie-mat-cbx-inner-mr;
    }

    .mat-checkbox-label {
      font-size: $mpac-cookie-info__hdr-cnt-chk-cookie-mat-cbx-lbl-fs;
      font-weight: $mpac-cookie-info__hdr-cnt-chk-cookie-mat-cbx-lbl-fw;
    }

    .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-label,
    .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-label {
      color: $mpac-cookie-info__hdr-cnt-chk-cookie-mat-cbx--disabled-lbl-fw;
    }
  }
}
