// include bootstrap functions so we can use them for our variables
@import 'bootstrap/scss/functions';

//----------------------
// Global namespace
//----------------------
$nsglobal: 'mpac-';

// TODO: Clean! Decontaminate!

// Characters which are escaped by the escape-svg function
// copied from Bootstrap file because of circular dependency with the functions
$escaped-characters: (('<', '%3c'), ('>', '%3e'), ('#', '%23'), ('(', '%28'), (')', '%29')) !default;

/* ==== Font Awesome ==== */
$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';
$fa-custom-font-path: '~src/assets/fonts/font-awesome-custom';

//----------------------
// Colors
//----------------------

// Theme Colors
$primary: #494a57 !default;
$primary70: #494a57b3 !default;
$color-formimset: rgba(0, 0, 0, 0.075) !default;
$secondary: #da262e !default;
$accent: #30529a !default;
$light: #e4e5e6 !default;
$dark: $primary !default;
$success: #2bac00 !default;
$info: $accent !default;
$danger: $secondary !default;
$gray-300: #dee2e6 !default;
$visible: rgba($success, 0.75) !default;
$invisible: rgba($danger, 0.75) !default;

// General
$body-color: #717171 !default;
$body-bg: #f7f7f7 !default;

// other
$color-white: #ffffff !default;
$color-silver: #cccccc !default;
$color-black: #000000 !default;
$color-iron: #e4e4e6 !default;
$color-alabaster: #fafafa !default;
$color-wild-sand: #f5f5f5 !default;
$color-whisper: #fbfbfd !default;
$color-jungle-mist: #bed4d7 !default;
$color-aluminium: #a6a7ac !default;
$color-silver-chalice: #b0b0b0 !default;
$color-silver: #bdbdbd !default;
$color-shark: #26272d !default;
$color-gray: #8e8e8e !default;
$color-dogger: #2f8efe !default;
$color-jap-laurel: #008100 !default;
$color-deep-sea: #0c5460 !default;
$color-california: #f89406 !default;
$color-nile: #192b50 !default;
$color-botticelli: #d6dceb !default;
$color-milk-punch: #fff5d4 !default;
$color-mercury: #e6e6e6 !default;
$color-mercury-2: #e1e1e1 !default;
$color-dove: #727272 !default;
$color-gallery: #ececec !default;
$color-st-tropaz: #2c519b !default;
$color-conrete: #f2f2f2 !default;
$color-sunset-highlight: #ffe59180 !default;
// $color-

$profile-image-color: #7db9ff !default;
$memory-book-color: #ffd344 !default;
$flower-service-color: #ff8944 !default;
$public-visible-color: #8fce7d !default;
$morticians-only-color: #ce7d7d !default;
$selection-border-color: $light !default;
$selection-border-color-selected: #0000ff !default;

$theme-colors: [] !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'accent': $accent,
    'body': $body-color,
    'visible': $visible,
    'invisible': $invisible,
  ),
  $theme-colors
) !default;

$component-active-bg: theme-color('accent') !default;

// Font base settings
$font-family-system: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-sans-serif: 'FF Meta W07', $font-family-system !default;

$font-size--default: 14 !default;
$font-size-base: 1.05rem !default;
$font-size--medium: $font-size-base * 0.9 !default;
$font-size--small: $font-size-base * 0.8 !default;
$font-size--smaller: $font-size-base * 0.65 !default;
$font-size-sm: $font-size-base * 0.9 !default;
$font-size-lg: $font-size-base * 1.3 !default;
$line-height-base: 1.4 !default;

//----------------------
// Animation
//----------------------
$mpac-animation-duration--help: 600;
$mpac-animation-duration--def: 500;
$mpac-animation-duration--short: 250;
$mpac-animation-duration--very-short: 125;

//-----------------------
// Input container box-shadow (input field + button)
// ----------------------
$mpac-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba($color-aluminium, 0.6) !default;

// Heading font settings
$headings-line-height: 1.5 !default;
$headings-color: #717171 !default;
$headings-font-weight: 500 !default; // medium

$navbar-brand-font-size: $font-size-base * 1.7 !default;
$dependant-demo-font-size: $font-size-base * 1.7 !default;

$h1-font-size: $font-size-base * 2.5 !default; // 40px
$h2-font-size: $font-size-base * 1.75 !default; // 28px
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base * 0.75 !default;

$h1-font-weight: 400 !default;
$h1-margin-bottom: 0.8rem !default;

$navbar-brand-font-weight: 450 !default;
$navbar-brand-font-style: italic !default;
$navbar-brand-line-height: 1.5 !default;

// Link settings
$link-hover-decoration: none !default;
$link-color: $body-color !default;

$body-min-width: 320 !default;
// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1300px,
  fhd: 1920px,
  uhd: 2880px,
) !default;

// Container
$container-max-widths: (
  sm: 576px,
  md: 768px,
  lg: 960px,
  xl: 1200px,
  fhd: 1500px,
  uhd: 2000px,
) !default;

$mpac-modal-overlay-z-index: 1050 !default;

// Shadow settings
$box-shadow-sm: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.12), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.24) !default;
$box-shadow: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16), 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.23) !default;
$box-shadow-lg: 0 2px 24px rgba(0, 0, 0, 0.1) !default;
$box-shadow--alt: 0 -3px 8px 3px rgba(0, 0, 0, 0.075) !default;
$box-shadow-bt: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16), 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.23) !default;
$hint-tooltip-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);

//----------------------
// Bootstrap options
//----------------------
$enable-rounded: true;
$border-radius: 2px;
$enable-shadows: true;
$enable-responsive-font-sizes: true;

// Global spacing
$spacer: 1rem !default;
$spacer--one-oneq: 1.25rem !default;
$spacer--half: 0.5rem !default;
$spacer--quarter: 0.25rem !default;
$spacer--alert: (
  tb: 0.75rem,
  lr: 1.25rem,
) !default;

// Table settings
$table-accent-bg: $gray-300 !default;

// Form settings
$form-grid-gutter-width: 40px !default;

// Input settings
$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 0.9rem !default;
$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-padding-y-lg: 0.7rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-line-height: $line-height-base !default;

$input-padding-x: 0.75rem !default;
$input-padding-y: 0.5rem !default;

$input-border-color: rgba($primary, 0.7) !default;
$input-disabled-border-color: rgba($input-border-color, 0.4) !default;
$input-border-width: 1px !default;
$input-border-radius: $border-radius !default;
$input-bg: $color-white !default;
$input-disabled-bg: rgba($input-bg, 0.6) !default;
$input-color: $body-color !default;
$input-disabled-color: rgba($input-color, 0.6) !default;
$input-line-height: $line-height-base !default;
$input-placeholder-color: rgba($body-color, 0.75) !default;

$input-group-h: 40px !default;
$input-group-addon-bg: $body-color !default;
$input-group-addon-color: $color-white !default;
$input-group-border-radius: rem(2) !default;
$input-group-border-radius-sm: $input-group-border-radius !default;
$input-group-border-radius-lg: $input-group-border-radius !default;

$btn-line-height: $input-btn-line-height !default;
$btn-box-shadow: none !default;
$btn-border-radius: #{$btn-line-height}rem;

// Modals
$modal-content-border-radius: $input-border-radius !default;

// custom controls
$custom-control-indicator-bg: $input-bg !default;
$custom-control-indicator-active-bg: $accent !default;
$custom-control-indicator-checked-bg: $accent !default;
$custom-control-indicator-disabled-bg: $custom-control-indicator-bg !default;
$custom-control-indicator-checked-disabled-bg: rgba($custom-control-indicator-active-bg, 0.6) !default;
$custom-control-label-disabled-color: rgba($input-color, 0.6) !default;

// custom switch
$custom-switch-min-height: 3rem !default;
$custom-switch-indicator-size: 1.2rem !default;
$custom-switch-width: 1.2rem * 2 !default;
$custom-switch-offset-left: 3.2rem !default;
$custom-switch-background-active: lighten($primary, 25%) !default;
$custom-switch-border-active: none !default;
$custom-switch-border-active-disabled: none !default;
$custom-switch-indicator-border-radius: $input-border-radius !default;
$custom-switch-indicator-border: 1px solid rgba($body-color, 0.75) !default;
$custom-switch-indicator-border-disabled: 1px solid rgba($body-color, 0.5) !default;
$custom-switch-indicator-border-active: $custom-switch-indicator-border !default;

// custom select
// the downward caret including its background is a svg graphic.
$custom-select-padding-x: $input-padding-x !default;
$custom-select-padding-y: 0.25 * $spacer !default; // needed for indicator size computation
$custom-select-bg-size: auto 104% !default; // width x height, oversized because of funny webkit rendering
$custom-select-indicator-color: #b1b1b2 !default;
$custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 70'><path style='fill: #{$custom-select-indicator-color};' d='M.5,69.5V.5h29v69Zm8.08-38a1.48,1.48,0,0,0-1,2.52l6.29,6.29a1.46,1.46,0,0,0,2.09,0L22.21,34a1.48,1.48,0,0,0-1.05-2.52Z'/></svg>") !default;
$custom-select-background: escape-svg($custom-select-indicator) no-repeat right -1px center / $custom-select-bg-size !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

// company image assets selections
$selection-border: 1px solid $selection-border-color !important;
$selection-border-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2) !default;
$selection-border-selected: 1px solid $selection-border-color-selected !important;
$selection-border-shadow-selected: 0 0 8px 5px rgba(112, 112, 112, 0.4) !default;

//This needs to stay here, otherwise all our vars will overwrite theirs and boom!
@import 'bootstrap/scss/variables';
