@import 'src/custom-scss/variables';

.tooltip {
  &.show {
    opacity: 1;
  }

  .tooltip-arrow {
    display: none;
  }

  .tooltip-inner {
    background-color: $color-white;
    border-radius: 4px;
    box-shadow: $hint-tooltip-box-shadow;
    color: $body-color;
    font-size: 14px;
    line-height: 18px;
    padding: 8px 12px;
    text-align: left;
  }
}
