// make the bootstrap custom switch even more custom!
@use 'sass:math';

.custom-switch {
  align-items: center;
  display: flex;
  min-height: $custom-switch-min-height;
  padding-left: 0;

  @include media-breakpoint-up(xl) {
    height: 100%;
  }

  .custom-control-label {
    margin-left: $custom-switch-offset-left;
  }

  .custom-control-label {
    &::before, &::after {
      top: 50% !important;
      transform: translate(0, -50%);
    }

    &::after {
      background-color: $custom-control-indicator-bg;
      border: $custom-switch-indicator-border;
      border-radius: $custom-switch-indicator-border-radius;
      left: calc(#{- ($custom-switch-width + $custom-control-gutter)} - #{math.div($custom-switch-indicator-size, 11)});
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: $custom-switch-background-active;
      border: $custom-switch-border-active;
    }

    &::after {
      background-color: $custom-control-indicator-active-bg;
      transform: translate($custom-switch-width - $custom-control-indicator-size, -50%);
    }
  }

  .custom-control-input:disabled ~ .custom-control-label {
    &::before {
      background-color: rgba($custom-control-indicator-disabled-bg, 0.6);
      border: $custom-switch-indicator-border-disabled;
    }

    &::after {
      background-color: lighten($custom-control-indicator-disabled-bg, 50%);
      border: $custom-switch-indicator-border-disabled;
    }
  }

  .custom-control-input:checked:disabled ~ .custom-control-label {
    &::before {
      background-color: rgba($custom-switch-background-active, 0.6);
      border: $custom-switch-border-active-disabled;
    }

    &::after {
      background-color: rgba($custom-control-indicator-active-bg, 0.6);
    }
  }
}

.custom-checkbox {
  .custom-control-label:not(.has-link) {
    align-items: center;
    display: flex;

    &::before, &::after {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}
