@import 'src/custom-scss/variables';

fieldset {
  margin-bottom: $spacer;

  > label {
    display: block;
    font-size: $h3-font-size;
    font-weight: 500;
    margin-bottom: $spacer;
    padding: 0.5 * $spacer 0;
  }

  .col-form-label, .col-form-label-lg, .col-form-label-sm {
    font-weight: bold;
  }
}
