@use 'sass:math';

$flag-icon-css-path: '/assets' !default;

.flag-icon-background {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}
 
.flag-icon {
  @extend .flag-icon-background;
  display: inline-block;
  line-height: 1em;
  position: relative;
  width: math.div(4, 3) * 1em;

  &::before {
    content: '\00a0';
  }
}
 
@mixin flag-icon($country) {
  .flag-icon-#{$country} {
    background-image: url(#{$flag-icon-css-path}/#{$country}.svg);
  }
}
 
@import '../../node_modules/flag-icon-css/sass/flag-icon-list.scss';
