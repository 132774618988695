//
// Override alternate buttons to include
// a lighter hover background color than default
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value, lighten($value, 7.5%), lighten($value, 10%));
  }
}
