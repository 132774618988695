@import 'src/custom-scss/variables';
@import 'src/custom-scss/mixins';
@import 'src/custom-scss/rem';
@import 'bootstrap/scss/mixins';

$mpac-add-images-form__hint-box-mh: rem(170) !default;

//----------------------
// General Styles for all add images forms
//----------------------
.#{$nsglobal}add-images-form {
  @include make-container;
  // &__form {
  // }

  a {
    font-weight: bold;
    text-decoration: underline !important;
    cursor: pointer;
  }

  ul {
    max-height: 40vh;
    overflow-y: auto;
    padding: 0;
  }


  .form-row {
    @include media-breakpoint-down(md) {
      margin-top: $spacer;
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .row {
    &--margin {
      margin-bottom: $spacer;
    }

    .hint-box {
      margin-bottom: 0;
      min-height: $mpac-add-images-form__hint-box-mh;

      @include media-breakpoint-down(md) {
        height: auto;
        margin-top: $spacer;
        min-height: 0;
      }
    }

    .validation {
      margin-top: $spacer;
    }
  }
}
