@import 'src/custom-scss/variables';
//----------------------
// Datepicker Variables
//----------------------
$mpac-datepicker-b: 1px solid $primary70 !default;
$mpac-datepicker-bs: inset 0 1px 1px $color-formimset !default;
$mpac-datepicker__input-b-r: none !default;
$mpac-datepicker__input-b-rad-r: 0 !default;
$mpac-datepicker__input-bgc: transparent !default;
$mpac-datepicker__input-pos: relative !default;
$mpac-datepicker__input-pos-t: 1px !default;
$mpac-datepicker__input-h: $input-group-h !default;
$mpac-datepicker__input-mx-h: 100% !default;
$mpac-datepicker__input-bxs: border-box !default;
$mpac-datepicker__input-ico-btn-h: $input-group-h !default;
$mpac-datepicker__input-ico-btn-mx-h: 100% !default;
$mpac-datepicker__input-ico-btn-lh: inherit !default;
$mpac-datepicker__input-p: (
  tb: $spacer--half,
  lr: $spacer * 0.75,
) !default;
$mpac-datepicker__trigger-b-rad-r: 2px !default;
