@import 'src/custom-scss/variables';
@import 'src/custom-scss/rem';

//----------------------
// Overflow menu variables
//----------------------
$mapc-overflow-menu-bs: $box-shadow--alt !default;
$mpac-overflow-menu-mw: rem(50) !default;
$mpac-overflow-menu__pc-p: (
  tb: $spacer,
  lr: $grid-gutter-width * 0.5,
) !default;
$mpac-overflow-menu__pc-zidx: $mpac-modal-overlay-z-index - 1 !default;

//----------------------
// Toolbar
//----------------------
$mpac-overflow-menu__toolbar-d: block !default;
$mpac-overflow-menu__toolbar-p: 0 !default;

//----------------------
// Toolbar Wrap
//----------------------
$mpac-overflow-menu__toolbar__wrap-pt: 0 !default;

//----------------------
// Toolbar List
//----------------------
$mpac-overflow-menu__toolbar__lst-m: 0 !default;

//----------------------
// Toolbar element
//----------------------
$mpac-overflow-menu__toolbar-el-p: 0 !default;
$mpac-overflow-menu__toolbar-el-w: 100% !default;
$mpac-overflow-menu__toolbar-el-btn-w: 100% !default;
$mpac-overflow-menu__toolbar-el--last-mb: 0 !default;
