@import '../variables';

@mixin img-card-img {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;

  &:not(.loading-done) {
    animation: image-loading 1s infinite;
    background-color: rgba($dark, 0.3);
  }
}

@keyframes image-loading {
  0% {
    background-color: rgba($dark, 0.3);
  }

  50% {
    background-color: rgba($dark, 0.15);
  }

  100% {
    background-color: rgba($dark, 0.3);
  }
}
