@use 'sass:color';

@import 'src/custom-scss/variables';
@import 'src/custom-scss/mixins';
@import 'bootstrap/scss/mixins';

$nslocal: 'ngx-gdprcb';

//----------------------
// Cookie dialog overrides
//----------------------
$mpac-cookie-modal-p: (
  lr: $grid-gutter-width * 1.25,
  tb: 0,
) !default;
$mpac-cookie-modal-h: auto !default;
$mpac-cookie-modal-min-h: 40vh !default;
$mpac-cookie-modal-max-h: 90vh !default;
$mpac-cookie-modal-overlay--lg-mw: 70 vw !default;
$mpac-cookie-modal-overlay--sm-mw: 90 vw !default;
$mpac-cookie-banner-inner-gutter: $grid-gutter-width * 0.5 !default;
$mpac-cookie-banner-dialog-inner-borders: rem(1) solid $gray-300 !default;
$mpac-cookie-banner-ovf: hidden !default;
$mpac-cookie-banner-h100: 100% !default;
$mpac-cookie-banner-element-margins: rem(15) !default;

//----------------------
// Cookie banner variable overrides
//----------------------
$mpac-cookie-banner__link-c: $color-aluminium !default;
$mpac-cookie-banner__link-pl: 0 !default;
$mpac-cookie-banner__link-txt-fw: normal !default;
$mpac-cookie-banner__link-txt-pl: $spacer--half !default;
$mpac-cookie-banner__links-m: (
  lr: 0,
  tb: rem(20),
) !default;
$mpac-cookie-banner__links-lst-itm-m: (
  right: rem(50),
  bottom: $spacer--half,
) !default;
$mpac-cookie-banner__hdr-ftr-ptb: $spacer !default;
$mpac-cookie-banner__hdr-hdl-fs: $h3-font-size !default;
$mpac-cookie-banner__hdr-hdl-lh: 120% !default;
$mpac-cookie-banner__hdr-h: rem(57) !default;
$mpac-cookie-banner__hdr-h--xs: $mpac-cookie-banner__hdr-h * 1.5 !default;
$mpac-cookie-banner__hdr-h-ovf: hidden !default;
$mpac-cookie-banner__cnt-ovf: auto !default;
$mpac-cookie-banner__cookies-mb: 0 !default;
$mpac-cookie-banner__cookies-lst-itm-m: (
  lr: 0,
  tb: rem(20),
  lastChildBottom: 0,
) !default;
$mpac-cookie-banner__cookies-lst-itm-cookie-m: (
  lr: 0,
  tb: rem(5),
) !default;
$mpac-cookie-banner__cookies-lst-itm-cookie-mat-cbx-inner-mr: rem(15) !default;
$mpac-cookie-banner__cookies-lst-itm-cookie-mat-cbx-lbl-fw: bold !default;
$mpac-cookie-banner__cookies-lst-itm-cookie-mat-cbx--disabled-lbl-fw: $color-silver-chalice !default;
$mpac-cookie-banner__cookies-lst-itm-cookie-mat-cbx-lbl-fs: rem($font-size--default) !default;
$mpac-cookie-banner__cookies-lst-itm-dsc-pl: 2rem !default;
$mpac-cookie-banner__cookies-lst-itm-dsc-dp: block !default;
$mpac-cookie-banner__cookies-lst-itm-inf-ml: rem($spacer--quarter) !default;
$mpac-cookie-banner__cookies-lst-itm-inf-lh: 125% !default;

//----------------------
// Cookie Table
//----------------------
$mpac-cookie-banner__cnt--table-ovf-x: auto !default;
$mpac-cookie-banner__cnt--table-max-w: 100% !default;
$mpac-cookie-banner__cnt-table-cell-fs: $font-size--smaller !default;
$mpac-cookie-banner__cnt-table-min-w--xs: 95 vw !default;
$mpac-cookie-banner__cnt-table-min-w--md: 80 vw !default;
$mpac-cookie-banner__cnt-table-min-w--lg: 50 vw !default;
$mpac-cookie-banner__cnt-table-min-w--xl: 35 vw !default;

//----------------------
// Footer
//----------------------
$mpac-cookie-banner__ftr-h: rem(50) !default;
$mpac-cookie-banner__ftr-h-ovf: hidden !default;
$mpac-cookie-banner__ftr-wrap-lr--xs-w: 100% !default;
$mpac-cookie-banner__ftr-wrap-lr--xs-jsc: space-between !default;
$mpac-cookie-banner__ftr-fct--xs-mb: $spacer--half;
$mpac-cookie-banner__ftr-fct--xs-w: 100% !default;
$mpac-cookie-banner__ftr-fct-btn--xs-w: 100% !default;
$mpac-cookie-banner__ftr-fct-btn-pc: $primary !default;
$mpac-cookie-banner__ftr-fct-btn-ps: $color-white !default;
$mpac-cookie-banner__ftr-fct-btn--primary-pc: $secondary !default;
$mpac-cookie-banner__ftr-fct-btn--primary-hover-bgc: color.adjust($mpac-cookie-banner__ftr-fct-btn--primary-pc,
$lightness: 15%) !default;
$mpac-cookie-banner__ftr-fct-btn--light-c: $color-silver-chalice !default;
$mpac-cookie-banner__ftr-fct-btn--light-br-c: transparent !default;
$mpac-cookie-banner__ftr-fct-btn--light--hover-bgc: transparent !default;
$mpac-cookie-banner__ftr-fct-btn--light--hover-c: color.adjust($color-silver-chalice, $lightness: -15%) !default;
$mpac-cookie-banner__ftr-fct-btn--back-txt-pl: $spacer--half !default;
$mpac-cookie-banner__cookie-chkb-bgc: $accent !default;

//----------------------
// Global overrides
//----------------------
.#{$nslocal}-overlay {
  @include media-breakpoint-only(lg) {
    max-width: $mpac-cookie-modal-overlay--lg-mw !important;
  }

  @include media-breakpoint-down(sm) {
    max-width: $mpac-cookie-modal-overlay--sm-mw !important;
  }

  .mat-dialog-container {
    @include scrollbar-narrow;
    height: auto;
    max-height: $mpac-cookie-modal-max-h;
    min-height: $mpac-cookie-modal-min-h;
  }
}

.ngx-gdpr-modal {
  height: $mpac-cookie-banner-h100;
  padding: map-get($mpac-cookie-modal-p, tb) map-get($mpac-cookie-modal-p, lr);
}

//----------------------
// Cookie checkbox specific styles
//----------------------

.ngx-cookie-chkb {
  .mat-checkbox-indeterminate.mat-accent:not(.mat-checkbox-disabled) .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent:not(.mat-checkbox-disabled) .mat-checkbox-background {
    background-color: $mpac-cookie-banner__cookie-chkb-bgc;
  }
}

//----------------------
// Cookie banner style overwrit1es
//----------------------
.#{$nslocal}.#{$nsglobal}cookie-banner {
  height: $mpac-cookie-banner-h100;
  overflow: $mpac-cookie-banner-ovf;

  .#{$nslocal} {
    &__wrap {
      height: $mpac-cookie-banner-h100;
    }

    &__header,
    &__cnt,
    &__ftr {
      padding-left: $mpac-cookie-banner-inner-gutter;
      padding-right: $mpac-cookie-banner-inner-gutter;
    }

    &__header,
    &__ftr {
      padding-bottom: $mpac-cookie-banner__hdr-ftr-ptb;
      padding-top: $mpac-cookie-banner__hdr-ftr-ptb;
    }

    //----------------------
    // Header
    //----------------------

    &__header {
      height: $mpac-cookie-banner__hdr-h;
      overflow: $mpac-cookie-banner__hdr-h-ovf;

      @include media-breakpoint-only(xs) {
        height: $mpac-cookie-banner__hdr-h--xs;
      }

      &-hdl {
        font-size: $mpac-cookie-banner__hdr-hdl-fs;
        line-height: $mpac-cookie-banner__hdr-hdl-lh;
      }
    }

    //----------------------
    // General Link styles
    //----------------------

    &__link {
      color: $mpac-cookie-banner__link-c;
      padding-left: $mpac-cookie-banner__link-pl;

      &::before {
        display: none;
      }

      &-ico {
        @include fa-icon--s($fa-var-external-link);
      }

      &-txt {
        font-weight: $mpac-cookie-banner__link-txt-fw;
        padding-left: $mpac-cookie-banner__link-txt-pl;
      }
    }

    //----------------------
    // Link List
    //----------------------

    &__links {
      margin: map-get($mpac-cookie-banner__links-m, tb) map-get($mpac-cookie-banner__links-m, lr);

      &-lst-itm {
        margin-right: map-get($mpac-cookie-banner__links-lst-itm-m, right);
        @include media-breakpoint-down(sm) {
          margin-bottom: map-get($mpac-cookie-banner__links-lst-itm-m, bottom);
        }
      }
    }

    //----------------------
    // Cookie category settings
    //----------------------

    &__cookies {
      border-top: $mpac-cookie-banner-dialog-inner-borders;
      margin-bottom: $mpac-cookie-banner__cookies-mb;

      &-form {
        &-lst {
          &-itm {
            margin: map-get($mpac-cookie-banner__cookies-lst-itm-m, tb)
              map-get($mpac-cookie-banner__cookies-lst-itm-m, lr);

            //----------------------
            // Checkbox
            //----------------------

            lib-gdpr-checkbox-control {
              margin: map-get($mpac-cookie-banner__cookies-lst-itm-cookie-m, tb)
                map-get($mpac-cookie-banner__cookies-lst-itm-cookie-m, lr);
            }

            .mat-checkbox-inner-container {
              margin-right: $mpac-cookie-banner__cookies-lst-itm-cookie-mat-cbx-inner-mr;
            }

            .mat-checkbox-label {
              font-size: $mpac-cookie-banner__cookies-lst-itm-cookie-mat-cbx-lbl-fs;
              font-weight: $mpac-cookie-banner__cookies-lst-itm-cookie-mat-cbx-lbl-fw;
            }

            .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-label,
            .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-label {
              color: $mpac-cookie-banner__cookies-lst-itm-cookie-mat-cbx--disabled-lbl-fw;
            }

            //----------------------
            // Description
            //----------------------

            &-inf,
            &-desc {
              display: $mpac-cookie-banner__cookies-lst-itm-dsc-dp;
              line-height: $mpac-cookie-banner__cookies-lst-itm-inf-lh;
              padding-left: $mpac-cookie-banner__cookies-lst-itm-dsc-pl;
            }
          }

          li {
            &:last-of-type {
              margin-bottom: map-get($mpac-cookie-banner__cookies-lst-itm-m, lastChildBottom);
            }
          }
        }
      }
    }

    //----------------------
    // Cookie Table
    //----------------------

    &__cnt {
      @include scrollbar-narrow;

      height: calc(100% - #{$mpac-cookie-banner__hdr-h} - #{$mpac-cookie-banner__ftr-h} -
      (5 * #{$mpac-cookie-banner-element-margins}));

      overflow: $mpac-cookie-banner__cnt-ovf;

      @include media-breakpoint-only(xs) {
        height: calc(100% - #{$mpac-cookie-banner__hdr-h--xs} - #{$mpac-cookie-banner__ftr-h} * 2 -
        (5 * #{$mpac-cookie-banner-element-margins}));
      }

      &-wrap {
        max-width: $mpac-cookie-banner__cnt--table-max-w;
        overflow-x: $mpac-cookie-banner__cnt--table-ovf-x;
      }

      .mat-table {
        @include media-breakpoint-up(xs) {
          min-width: $mpac-cookie-banner__cnt-table-min-w--xs;
        }
        @include media-breakpoint-up(md) {
          min-width: $mpac-cookie-banner__cnt-table-min-w--md;
        }
        @include media-breakpoint-up(lg) {
          min-width: $mpac-cookie-banner__cnt-table-min-w--lg;
        }
        @include media-breakpoint-up(xl) {
          min-width: $mpac-cookie-banner__cnt-table-min-w--xl;
        }

        .mat-cell {
          font-size: $mpac-cookie-banner__cnt-table-cell-fs;
        }
      }
    }
    //----------------------
    // Footer
    //----------------------

    &__ftr {
      height: $mpac-cookie-banner__hdr-h;
      overflow: $mpac-cookie-banner__ftr-h-ovf;

      @include media-breakpoint-only(xs) {
        height: $mpac-cookie-banner__ftr-h * 2;
      }

      &-wrap {
        &-left,
        &-right {
          @include media-breakpoint-only(xs) {
            justify-content: $mpac-cookie-banner__ftr-wrap-lr--xs-jsc;
            width: $mpac-cookie-banner__ftr-wrap-lr--xs-w;
          }
        }
      }

      &-fct {
        margin-bottom: $mpac-cookie-banner__ftr-fct--xs-mb;

        @include media-breakpoint-only(xs) {
          width: $mpac-cookie-banner__ftr-fct--xs-w;
        }

        &-btn {
          color: $mpac-cookie-banner__ftr-fct-btn-pc;

          @include media-breakpoint-only(xs) {
            width: $mpac-cookie-banner__ftr-fct-btn--xs-w;
          }

          &:hover,
          &:active {
            background-color: $mpac-cookie-banner__ftr-fct-btn-pc;
            color: $mpac-cookie-banner__ftr-fct-btn-ps;
          }
        }

        &-btn--primary {
          background-color: $mpac-cookie-banner__ftr-fct-btn--primary-pc;
          border-color: $mpac-cookie-banner__ftr-fct-btn--primary-pc;
          color: $mpac-cookie-banner__ftr-fct-btn-ps;

          &:hover,
          &:active {
            background-color: $mpac-cookie-banner__ftr-fct-btn--primary-hover-bgc;
            border-color: $mpac-cookie-banner__ftr-fct-btn--primary-hover-bgc;
            color: $mpac-cookie-banner__ftr-fct-btn-ps;
          }
        }

        //----------------------
        // Back button
        //----------------------

        &-btn--back {
          .#{$nslocal}__ftr-fct-btn {
            &-ico {
              @include fa-icon($fa-var-arrow-circle-left);
            }

            &-txt {
              padding-left: $mpac-cookie-banner__ftr-fct-btn--back-txt-pl;

              @include media-breakpoint-down(sm) {
                display: none;
              }
            }
          }
        }

        //----------------------
        // Light/Stealth button
        //----------------------

        &-btn--light {
          border-color: $mpac-cookie-banner__ftr-fct-btn--light-br-c;
          color: $mpac-cookie-banner__ftr-fct-btn--light-c;

          &:hover,
          &:active {
            background: $mpac-cookie-banner__ftr-fct-btn--light--hover-bgc;
            border-color: $mpac-cookie-banner__ftr-fct-btn--light-br-c;
            color: $mpac-cookie-banner__ftr-fct-btn--light--hover-c;
          }
        }
      }
    }
  }
}
