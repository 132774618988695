@import 'src/custom-scss/variables';
@import 'src/custom-scss/mixins';
@import 'src/custom-scss/rem';
@import 'bootstrap/scss/mixins';
@import './overflow-menu.variables';

//----------------------
// Overflow menu styles
//----------------------
.#{$nsglobal}overflow-menu {
  box-shadow: $mapc-overflow-menu-bs;
  min-width: $mpac-overflow-menu-mw;
  z-index: $mpac-overflow-menu__pc-zidx;

  //----------------------
  // Popover overrides
  //----------------------

  .popover-content {
    padding: map-get($mpac-overflow-menu__pc-p, tb) map-get($mpac-overflow-menu__pc-p, lr);
  }

  //----------------------
  // Toolbar
  //----------------------
  .#{$nsglobal}toolbar {
    display: $mpac-overflow-menu__toolbar-d;
    padding: $mpac-overflow-menu__toolbar-p;

    &__wrap {
      padding: $mpac-overflow-menu__toolbar__wrap-pt;
    }

    &__lst {
      margin: $mpac-overflow-menu__toolbar__lst-m;
    }

    //----------------------
    // Toolbar element
    //----------------------
    .#{$nsglobal}toolbar-el {
      padding: $mpac-overflow-menu__toolbar-el-p;
      width: $mpac-overflow-menu__toolbar-el-w;

      &:last-of-type {
        margin-bottom: $mpac-overflow-menu__toolbar-el--last-mb;
      }

      .btn {
        width: $mpac-overflow-menu__toolbar-el-btn-w;
      }
    }
  }
}
