@import 'src/custom-scss/variables';
@import 'strip-unit';

.modal-backdrop {
  z-index: $mpac-modal-overlay-z-index;
}

.cdk-overlay-container {
  z-index: $mpac-modal-overlay-z-index + 1;
}

.modal-dialog {
  // increase max-width after breakpoint lg to the max-width
  // of the container @lg
  @include media-breakpoint-up(lg) {
    max-width: map_get($container-max-widths, lg);
  }

  @include media-breakpoint-only(md) {
    max-width: strip-unit(map_get($container-max-widths, md)) * 0.8 + px;
  }

  button.no-outline:focus {
    outline: none;
  }
}
