@import 'src/custom-scss/variables';
@import 'src/custom-scss/rem';
@import 'bootstrap/scss/mixins';
@import 'src/custom-scss/mixins';
@import './forms.variables';

//----------------------
// Mpac form styles
//----------------------
.#{$nsglobal}form {
  //----------------------
  // Outer wrapper/spinner
  //----------------------
  // &__wrap {
  // }

  //----------------------
  // Main Content Area
  //----------------------

  &__cnt {
    @include make-container;
  }

  //----------------------
  // Rows
  //----------------------

  &__row,
  &__sec {
    @include make-row;
  }

  //----------------------
  // Fields
  //----------------------

  &__field {
    @include make-col-ready;
    @include make-col-auto;

    &--checkbox {
      .custom-control-label,
      .custom-control-input {
        margin-left: $mpac-form__field--checkbox-of-l;
      }
    }

    &--radio {
      margin-left: $mpac-form__field--radio-of-l;
    }
  }

  //----------------------
  // Sub Sections
  //----------------------

  &__sec {
    @include make-row;

    &-wrap {
      @include make-col-ready;
      @include make-col(12);
      @include no-gutter;
    }

    &-cnt {
      @include make-container;
      @include no-gutter;

      &-hdr,
      &-flds {
        @include make-row;
        @include no-gutter--row;
      }

      &-hdr {
        margin-bottom: $mpac-form__sec-cnt-hdr-mb;

        &-txt {
          @include make-col-ready;
          @include make-col-auto;
        }
      }

      &-line {
        @include make-col(12);
        background-color: $mpac-form__sec-cnt-line-bgc;
        height: $mpac-form__sec-cnt-line-h;
        left: $grid-gutter-width * 0.5;
        margin-top: $mpac-form__sec-cnt-line-mt;
        position: $mpac-form__sec-cnt-line-pos;
        width: $mpac-form__sec-cnt-line-w;
      }
    }
  }
}
