@import 'src/custom-scss/variables';
@import 'src/custom-scss/rem';
@import 'bootstrap/scss/mixins';
@import 'src/custom-scss/mixins';
@import './material-dialog.variables.scss';

//----------------------
// Add Address Dialog styles
//----------------------
.#{$nsglobal}mat-dlg {
  max-width: $mpac-mat-dlg-mw;

  @include media-breakpoint-only(xs) {
    height: $mpac-mat-dlg--xs-h;
  }

  //----------------------
  // Header
  //----------------------

  &__hdr {
    border-bottom-color: $mpac-mat-dlg-actn-bc;
    margin-bottom: $mpac-mat-dlg-hdr-mb;
    padding-left: $mpac-mat-dlg-hdr-pl;
    padding-right: $mpac-mat-dlg-hdr-pr;

    &-close {
      outline: $mpac-mat-dlg-close-ol;
    }
  }

  //----------------------
  // Content
  //----------------------

  &__cnt {
    @include scrollbar-narrow;
    @include touch-overflow;

    display: $mpac-mat-dlg__cnt-d;
    height: $mpac-mat-dlg__cnt-mxh;
    margin: $mpac-mat-dlg__cnt-m;
    max-height: $mpac-mat-dlg__cnt-mxh;
    overflow: $mpac-mat-dlg__cnt-ovf;
    -webkit-overflow-scrolling: touch;
    padding: $mpac-mat-dlg__cnt-p;
  }

  //----------------------
  // Footer
  //----------------------

  &__ftr {
    @include make-container;
    border-top: $mpac-mat-dlg-actn-bw$mpac-mat-dlg-actn-bs$mpac-mat-dlg-actn-bc;

    display: $mpac-mat-dlg__ftr-d;
    margin: map-get($mpac-mat-dlg__ftr-m, tb) map-get($mpac-mat-dlg__ftr-m, lr) !important;
    padding: map-get($mpac-mat-dlg__ftr-p, t) map-get($mpac-mat-dlg__ftr-p, lr) map-get($mpac-mat-dlg__ftr-p, b) !important;
    width: $mpac-mat-dlg__ftr-w;

    &-wrap {
      @include make-row;
      @include no-gutter--row;

      justify-content: $mpac-mat-dlg__ftr-wrap-jc;
      width: $mpac-mat-dlg__ftr-wrap-w;
    }

    //----------------------
    // Function button
    //----------------------

    &-fct {
      @include make-col-ready;
      @include make-col-auto;
      margin-bottom: $mpac-mat-dlg__ftr-fct-mb;

      &--left {
        flex: $mpac-mat-dlg__ftr-ftc--left-f;
      }

      .#{$nsglobal}not-box {
        padding-left: $mpac-mat-dlg__ftr-ftc__not-box-plr;
        padding-right: $mpac-mat-dlg__ftr-ftc__not-box-plr;
      }

      &-ico {
        padding-right: $mpac-mat-dlg__ftr-fct-ico-pr;
      }

      &-btn--ico-only {
        &.#{$nsglobal}mat-dlg__ftr-fct-btn {
          display: $mpac-mat-dlg__ftr-ftc--ico-only-btn-d;
          height: $mpac-mat-dlg__ftr-ftc--ico-only-btn-s;
          justify-content: $mpac-mat-dlg__ftr-ftc--ico-only-btn-jc;
          padding: $mpac-mat-dlg__ftr-ftc--ico-only-btn-p;
          width: $mpac-mat-dlg__ftr-ftc--ico-only-btn-s;
        }

        .#{$nsglobal}mat-dlg__ftr-fct-btn-ico {
          align-self: $mpac-mat-dlg__ftr-ftc--ico-only-btn-ico-js;
          display: $mpac-mat-dlg__ftr-ftc--ico-only-btn-ico-d;
          padding-right: $mpac-mat-dlg__ftr-ftc--ico-only-btn-ico-pr;
        }
      }
    }
  }

  //----------------------
  // Mat Dialog overrides
  //----------------------

  .mat-dialog-container {
    overflow: $mpac-mat-dlg__cnt-ovf;
    padding-bottom: $mpac-mat-dlg__cnt-ptb;
    padding-top: $mpac-mat-dlg__cnt-ptb;

    > .ng-star-inserted {
      display: $mpac-mat-dlg-cnt__inserted-d;
      height: $mpac-mat-dlg-cnt__inserted-h;
      overflow: $mpac-mat-dlg-cnt__inserted-ovf;
    }
  }
}
