@import 'src/custom-scss/variables';

//----------------------
// Mpac form variables
//----------------------

//----------------------
// Fields
//----------------------
$mpac-form__field--checkbox-of-l: $spacer + $spacer--half !default;
$mpac-form__field--radio-of-l: -($grid-gutter-width * 0.5) !default;

//----------------------
// Sections
//----------------------
$mpac-form__sec-cnt-hdr-mb: $spacer !default;
$mpac-form__sec-cnt-line-pos: relative !default;
$mpac-form__sec-cnt-line-mt: $spacer--half !default;
$mpac-form__sec-cnt-line-w: calc(100% - #{$grid-gutter-width}) !default;
$mpac-form__sec-cnt-line-h: rem(1) !default;
$mpac-form__sec-cnt-line-bgc: $color-iron !default;
