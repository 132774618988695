@use 'sass:color';

@import 'src/custom-scss/variables';
@import 'src/custom-scss/rem';
@import 'src/custom-scss/mixins';
@import 'bootstrap/scss/mixins';

//----------------------
// General styles for the form elements in the application
//
// Please note: Due to the way this application was written, many styles are scattered throughout the application even
// though they should be here. Add them here - in an okay fashion - if you note that kind of thing.
//----------------------

.form-row {
  @extend .mb-2;
  @extend .mb-lg-3;

  [class^='col'] {
    @extend .mb-lg-1;
  }
}

form,
.form-row,
.form-group,
.form-control {
  ::placeholder {
    color: color.adjust($input-color, $lightness: 20%) !important;
  }
}

.form-group {
  .input-group {
    height: $input-group-h;

    .form-control {
      height: 100%;
    }
  }
}

.form-control {
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    color: $input-disabled-color;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }

  &.steps-hidden {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    &[type=number] {
      appearance: textfield;
    }
  }
}

// Prepend and append rounded corners
//
// These rulesets must come after the sizing ones to properly override sm and lg
// border-radius values when extending. They're more specific than we'd like
// with the `.input-group >` part, but without it, we cannot override the sizing.

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  @include border-right-radius(0);
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  @include border-left-radius(0);
}

.form-check {
  padding-left: 0;

  &-input {
    margin-left: 0;
  }

  &-label {
    margin-left: $spacer--one-oneq;
  }

  &.form-check-inline {
    .form-check-label {
      margin-left: 0;
    }
  }
}

//----------------------
// Address [intro] page
//----------------------

.form-control-pre-line {
  border-left: rem(1) solid $primary70;
  border-right: rem(1) solid $primary70;
  border-top: rem(1) solid $primary70;
  display: flex;
  justify-content: flex-end;

  &-btn {
    border-color: transparent;
    border-left-color: $primary70;
    border-radius: 0;
    color: $body-color;

    &:hover {
      color: $color-white;
    }
  }
}
