@font-face {
   font-family: "FF Meta W07";
   src: url("webfonts/2696dd44-2182-480a-adf9-5383fc925e48.woff2") format("woff2"), url("webfonts/01b6789a-1c66-43f6-9383-f09a6fbff2d4.woff") format("woff");
   font-weight: 350;
   font-style: normal;
}

@font-face {
   font-family: "FF Meta W07";
   src: url("webfonts/45c3d14d-16dd-4bab-97a5-b372cad49f18.woff2") format("woff2"), url("webfonts/afadf37a-d5b5-4152-837d-d72cf7210947.woff") format("woff");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "FF Meta W07";
   src: url("webfonts/12355427-142c-401c-9056-5b5f395623d8.woff2") format("woff2"), url("webfonts/b31497ff-61ec-4f5a-86f7-1225cd1bfa7c.woff") format("woff");
   font-weight: 400;
   font-style: italic;
}

@font-face {
   font-family: "FF Meta W07";
   src: url("webfonts/4f6922ed-bf0a-4d04-b1df-524bf4841e2e.woff2") format("woff2"), url("webfonts/61796356-212a-4c53-a6e6-4bc76f9d8c0c.woff") format("woff");
   font-weight: 450;
   font-style: normal;
}

@font-face {
   font-family: "FF Meta W07";
   src: url("webfonts/8be932fe-5cc0-4935-b75d-d3ffbcd70988.woff2") format("woff2"), url("webfonts/a4d05ac4-d7fb-46a7-ace3-24f01c868e88.woff") format("woff");
   font-weight: 450;
   font-style: italic;
}

@font-face {
   font-family: "FF Meta W07";
   src: url("webfonts/38b5004a-00f0-4ad8-a6f6-4c8fe48b074b.woff2") format("woff2"), url("webfonts/d499f279-cded-4d7c-95e7-5d22c8aff7d1.woff") format("woff");
   font-weight: 500;
   font-style: normal;
}

@font-face {
   font-family: "FF Meta W07";
   src: url("webfonts/32a02776-64bf-464c-9c4f-4f57df3d72d6.woff2") format("woff2"), url("webfonts/ddf9e5e1-6ea7-4b48-8343-bd8ccde5fa88.woff") format("woff");
   font-weight: 700;
   font-style: normal;
}
