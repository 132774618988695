@import 'src/custom-scss/variables';
@import 'src/custom-scss/rem';
@import 'bootstrap/scss/mixins';

//----------------------
// Add Address Dialog vars
//----------------------
$mpac-mat-dlg-mw: none !important !default;
$mpac-mat-dlg--xs-h: 100vh !default;
$mpac-mat-dlg__cnt-ptb: 0 !default;
$mpac-mat-dlg-cnt__inserted-d: block !default;
$mpac-mat-dlg-cnt__inserted-ovf: visible !default;
$mpac-mat-dlg-cnt__inserted-h: 100% !default;

//----------------------
// Header
//----------------------
$mpac-mat-dlg-hdr-pl: 0 !default;
$mpac-mat-dlg-hdr-pr: 0 !default;
$mpac-mat-dlg-hdr-mb: $spacer !default;

//----------------------
// Content
//----------------------
$mpac-mat-dlg__cnt-p-lr-offset: -24px !default; //offset for the material table col gutter width
$mpac-mat-dlg__cnt-d: block !default;
$mpac-mat-dlg__cnt-m: 0 $mpac-mat-dlg__cnt-p-lr-offset !default;
$mpac-mat-dlg__cnt-p: 0 24px !default;
$mpac-mat-dlg__cnt-ovf: auto !default;
$mpac-mat-dlg__cnt-mxh: 65vh !default;
$mpac-mat-dlg__cnt--xs-h: auto !default;

//----------------------
// Footer
//----------------------
$mpac-mat-dlg__ftr-d: flex !default;
$mpac-mat-dlg-actn-bw: 1px !default;
$mpac-mat-dlg-actn-bs: solid !default;
$mpac-mat-dlg-actn-bc: $color-gallery !default;
$mpac-mat-dlg-close-ol: none !important !default;

$mpac-mat-dlg__ftr-w: auto !default;
$mpac-mat-dlg__ftr-p: (
  t: $spacer,
  lr: 0,
  b: 0
) !default;
$mpac-mat-dlg__ftr-m: (
  lr: -$grid-gutter-width * 0.5,
  tb: 0
) !default;
$mpac-mat-dlg__ftr-p-tb: $spacer !default;
$mpac-mat-dlg__ftr-wrap-w: 100% !default;
$mpac-mat-dlg__ftr-wrap-jc: flex-end !default;

//----------------------
// Footer function
//----------------------
$mpac-mat-dlg__ftr-fct-mb: $spacer !default;
$mpac-mat-dlg__ftr-fct-d: flex !default;
$mpac-mat-dlg__ftr-fct-ico-pr: $spacer--half !default;
$mpac-mat-dlg__ftr-ftc--left-f: 1 1 auto !default;
$mpac-mat-dlg__ftr-ftc__not-box-plr: 0 !default;

//----------------------
// Footer function button [icon only] rounded
//----------------------
$mpac-mat-dlg__ftr-ftc--ico-only-btn-s: rem(38) !default;
$mpac-mat-dlg__ftr-ftc--ico-only-btn-d: flex !default;
$mpac-mat-dlg__ftr-ftc--ico-only-btn-jc: center !default;
$mpac-mat-dlg__ftr-ftc--ico-only-btn-p: 0 !default;
$mpac-mat-dlg__ftr-ftc--ico-only-btn-ico-d: flex !default;
$mpac-mat-dlg__ftr-ftc--ico-only-btn-ico-js: center !default;
$mpac-mat-dlg__ftr-ftc--ico-only-btn-ico-pr: 0 !default;
