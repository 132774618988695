@import 'src/custom-scss/variables';
@import 'src/custom-scss/rem';
@import 'src/custom-scss/mixins';
@import 'bootstrap/scss/mixins';
@import './forms-datepicker.variables';

//----------------------
// Date picker styles
//----------------------

.form-group.form-group--datepicker {
  //----------------------
  // Input
  //----------------------

  .form-control {
    background-color: $mpac-datepicker__input-bgc;
    border: $mpac-datepicker-b;
    border-bottom-right-radius: $mpac-datepicker__input-b-rad-r !important;
    border-right: $mpac-datepicker__input-b-r;
    border-top-right-radius: $mpac-datepicker__input-b-rad-r !important;
    height: $mpac-datepicker__input-h;
    padding: map-get($mpac-datepicker__input-p, tb) map-get($mpac-datepicker__input-p, lr);
    position: $mpac-datepicker__input-pos;
    top: $mpac-datepicker__input-pos-t;

    &.ng-invalid:not(.ng-untouched) {
      border-color: $secondary;
    }

    &:disabled {
      border-color: $input-disabled-border-color;
    }
  }

  //----------------------
  // Input
  //----------------------
  .mat-datepicker-input {
    box-sizing: $mpac-datepicker__input-bxs;
    height: $mpac-datepicker__input-h;
    max-height: $mpac-datepicker__input-mx-h;
  }

  .mat-datepicker-toggle {
    background-color: $mpac-datepicker__input-bgc;
    border: $mpac-datepicker-b;
    border-bottom-right-radius: $mpac-datepicker__trigger-b-rad-r;
    border-left: $mpac-datepicker__input-b-r;
    border-top-right-radius: $mpac-datepicker__trigger-b-rad-r;
    box-shadow: $mpac-datepicker-bs;
    height: $mpac-datepicker__input-h;
    max-height: $mpac-datepicker__input-ico-btn-mx-h;

    .mat-icon-button {
      height: $mpac-datepicker__input-ico-btn-h;
      line-height: $mpac-datepicker__input-ico-btn-lh;
    }

    &[ng-reflect-disabled='true'] {
      border-color: $input-disabled-border-color;
    }
  }

  .form-control.ng-invalid:not(.ng-untouched) + .mat-datepicker-toggle {
    border-color: $secondary;
  }
}
