@import '../variables';
@import '@fortawesome/fontawesome-pro/scss/fontawesome';
@import '@fortawesome/fontawesome-pro/scss/light';
@import '@fortawesome/fontawesome-pro/scss/solid';

%fa-icon-base {
  @extend %fa-icon;
}

@mixin fa-icon($icon: $fa-var-user) {
  @extend %fa-icon-base;
  @extend .fal;

  &::before {
    content: fa-content($icon);
  }
}

@mixin fa-icon--l($icon: $fa-var-user) {
  @extend %fa-icon-base;
  @extend .fal;

  &::before {
    content: fa-content($icon);
  }
}

@mixin fa-icon--s($icon: $fa-var-user) {
  @extend %fa-icon-base;
  @extend .fas;

  &::before {
    content: fa-content($icon);
  }
}
