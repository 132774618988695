@import '@angular/material/prebuilt-themes/indigo-pink.css';

/* =========== Variables ==========================*/
// includes bootstrap variables and functions
@import 'custom-scss/variables';

/* =========== Fonts ==============================*/
// Meta-OT
@import 'assets/fonts/meta-ot/meta-ot.css';
// FontAwesome
@import '@fortawesome/fontawesome-pro/scss/fontawesome';
@import '@fortawesome/fontawesome-pro/scss/light';
@import '@fortawesome/fontawesome-pro/scss/solid';
@import '@fortawesome/fontawesome-pro/scss/regular';
@import '@fortawesome/fontawesome-pro/scss/duotone';

/* =========== Styles ============================ */
// ==== vendor includes ====
// rest of the bootstrap includes; carousel, jumbotron and toasts removed
@import 'custom-scss/bootstrap-includes';
@import 'ngx-toastr/toastr-bs4-alert';

@import './node_modules/gdpr-cookie-banner/global_scss/global';

// custom styles
@import 'custom-scss/mixins';

@import 'custom-scss/custom-checkbox';
@import 'custom-scss/custom-select';
@import 'custom-scss/fieldset';
@import 'custom-scss/modal';
@import 'custom-scss/component-styles/material-dialog/material-dialog';
@import 'custom-scss/flags-patched';
@import 'custom-scss/btn-link';
@import 'custom-scss/candles';
@import 'custom-scss/txt-visibility';
@import 'custom-scss/import-components';
@import 'custom-scss/tooltip';
@import 'custom-scss/fontawesome-custom/custom-icons';

html,
body {
  height: 100%;
  min-height: stretch;
  min-width: rem($body-min-width);
  overflow-y: hidden;
  text-size-adjust: 100%;
  text-transform: none;
}

h1 {
  font-weight: $h1-font-weight;
  margin-bottom: $h1-margin-bottom;
}

a {
  outline: none !important;
}

//----------------------
// Reset the bullshit that bootstrap _reboot.scss sets.
// TODO: throw the bootstrap nonsense out entirely!
//----------------------

ul {
  padding-inline-start: 0;
}

li {
  list-style: none;
}

// TODO: Refactor! This file should only contain imports and very specific exeptions.
/* Own Classes
*  general classes
*/

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.hover-bg-light:hover {
  background-color: $light;
}

.ng-invalid.ng-touched {
  @extend .is-invalid;
}

.invalid-feedback {
  display: block;
}

.min-vh-25 {
  min-height: 25vh;
}

table {
  thead th {
    font-weight: normal;
  }

  th,
  td {
    padding: $spacer;
  }
}

.list-striped {
  & > * {
    &:nth-child(even) {
      background-color: $table-accent-bg;
    }
  }
}

.fa-circle-border {
  border: 1px solid white;
  border-radius: 50%;
  padding: 0.6rem;

  .active & {
    border-color: white;
  }
}

.icon-spacing-right {
  margin-right: 3px;
  margin-left: 3px;
}

/* classes for certain elements */

#toast-container {
  .ngx-toastr {
    box-shadow: $box-shadow-lg;
  }
}

.unbreakable {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.candle-color-blue {
  background-color: #51658e;
}

.candle-color-yellow {
  background-color: #ecc069;
}

.candle-color-green {
  background-color: #64a96c;
}

.candle-color-lightblue {
  background-color: #7ab2c7;
}

.candle-color-purple {
  background-color: #8f77a2;
}

.candle-color-red {
  background-color: #a76262;
}

a.not-active {
  color: black;
  cursor: default;
  pointer-events: none;
  text-decoration: none;
}
